import PageTitle from '../../../components/PageTitle' 

function Confirmacion() {
    return (
        <div>
            <PageTitle title="Inscripción Propuesta de Trabajo de Grado"/>
            <div className='propuesta-confirmacion'>
                <h2>
                    Usted ha enviado la inscripción de su propuesta satisfactoriamente
                </h2>
                <h3>Proximamente se le enviará una notificación con la asignación de su asesor</h3>
            </div>
        </div>
    )
}

export default Confirmacion;