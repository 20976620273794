import classnames from 'classnames';

function InfoField ({ label, value, size, block }) {
    return (
        <div className={classnames('info-field', {
            [`info-field--${size}`]: !!size,
            [`info-field--block`]: !!block,
        })}>
            <span>{label}:</span>
            <span>{value}</span>
        </div>
    )
}

export default InfoField;