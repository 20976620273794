import React from 'react';
import PageTitle from '../../../components/PageTitle';
import {teachers} from '../../../db/dummyTeach';
import Table from '../../../components/Table';
import Info from '../../../components/Info';
import Checkbox from '../../../components/Checkbox';
import { useNavigate } from 'react-router-dom';
import './styles.css';

function GestDocente() {

  const navigate = useNavigate();

  const columns = [
    {
      key: 'id',
      label: 'ID',
      style: { width: '40px'}
    },
    {
      key: 'name',
      label: 'Docente',
    },
    {
      key: 'last_name',
      label: 'Apellido',
    },
    {
      key: 'is_active',
      label: 'Estado',
      render: (is_active) => is_active ? 'Activo': 'Inactivo'
    },
    {
      key: 'num_Proyectos',
      label: 'Número de proyectos',
    },
    {
      key: 'horas',
      label: 'Horas',
    },
    {
      key: 'es_asesor',
      label: 'Asesor',
      style: { width: '85px' },
      render: (es_asesor) => <Checkbox checked={!!es_asesor} />
    },
    {
      key: 'es_jurado',
      label: 'Jurado',
      style: { width: '85px' },
      render: (es_jurado) => <Checkbox checked={!!es_jurado} />
    }
  ]

  const onSearch = (search) => {
    console.log(search, 'search')
  }

  const onRowClick = (data) => {
    navigate(`/cordinacion/gestdocentes/${data.id}`);
  }

  return (
    <React.Fragment>
        <PageTitle title="Gestión Docentes"/>
        <Info.Result label="Nº docentes registrados" value="#"/>
        <Info.Result label="Nº asesores activos" value="#"/>
        <Info.Result label="Nº docentes activos" value="#"/>
        <Info.Result label="Nº jurados activos" value="#"/>
        <Table 
          columns={columns} 
          data={teachers} 
          onSearch={onSearch} 
          onRowClick={onRowClick}
        />
    </React.Fragment>
  );
}

export default GestDocente;