import './styles.scss';
import PageTitle from '../../../components/PageTitle';
import Card from '../../../components/Card';
import { useNavigate } from "react-router-dom";

const allCards = Array.from({ length: 10 })

function Evaluacion() {
  const navigate = useNavigate();

  return (
    <>
      <PageTitle title="Evaluación" />
      <div className='propuestas-cards'>
        {allCards.map((_v, index) => (
          <Card 
            key={String(index)}
            title="Título corto del proyecto"
            onClick={() => navigate('/docente/evaluacion/detalle')}
            content={() => (
              <ul>
                <li>Nombre estudiante 1</li>
                <li>Nombre estudiante 2</li>
              </ul>
          )} />
        ))}
      </div>
    </>
  );
}

export default Evaluacion;