export const draft_projects = [
  {
    "id": 1,
    "name": "Proyecto A",
    "is_active": true,
    "teacher_id": 3
  },
  {
    "id": 2,
    "name": "Proyecto B",
    "is_active": false,
    "teacher_id": 7
  },
  {
    "id": 3,
    "name": "Proyecto C",
    "is_active": true,
    "teacher_id": 1
  },
  {
    "id": 4,
    "name": "Proyecto D",
    "is_active": true,
    "teacher_id": 9
  },
  {
    "id": 5,
    "name": "Proyecto E",
    "is_active": false,
    "teacher_id": 5
  }
]