import React, { useState } from 'react';
import PageTitle from '../../../components/PageTitle';
import Info from '../../../components/Info';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import {draft_projects} from '../../../db/dummyProjects';
import { useParams } from 'react-router-dom';

import './styles.scss';


function ProyectoDetalle() {
    const { id } = useParams();
    const [showAllForm, setShowAllForm] = useState(false);

    const project = draft_projects.find(item => String(item.id) === id);

    const handleOriginalChange = (e) => {
      setShowAllForm(e.target.value === 'si')
    }

  return (
    <div className='gestion-proyecto'>
        <PageTitle title="Gestión Proyectos"/>
        <div className='container pt0'>
            <PageTitle title="Generalidades"/>
            <div>
              <Info.Field label="Código" value={project?.id} />
              <Info.Field label="Título" value={project?.name} /> 
              <Info.Field label="Descripción" value="Descripción" />  
              <Info.Field label="Estado" value={project?.is_active ? 'Activo' : 'Inactivo'} />  
              <Info.Field label="Tipo" value="Tipo de trabajo de grado" />  
              <Info.Field label="Temática" value="Temática" />  
            </div>
            <PageTitle title="Estudiantes"/>
            <div>
              <Info.Group>
                <Info.Field label="Estudiantes" value="XXXXX" />
                <Info.Field label="Nombre" value="Nombre estudiante" />
              </Info.Group>
              <Info.Group>
                <Info.Field label="Estudiantes" value="XXXXX" />
                <Info.Field label="Nombre" value="Nombre estudiante" />
              </Info.Group>
            </div>
            <PageTitle title="Asesor"/>
            <div>
              <Info.Group>
                <Info.Field label="Opción 1" value="Nombre Asesor 1" />
                <Info.Field label="Opción 2" value="Nombre Asesor 2" />
              </Info.Group>
              <Info.Field label="Opción 3" value="Nombre Asesor 3" />
              <Info.Field label="Asesor seleccionado" value={
                <Select size="big" placeholder="Asesor seleccionado">
                  <option>Asesor 1</option>
                  <option>Asesor 2</option>
                  <option>Asesor 3</option>
                </Select>
              } />
            </div>
            <PageTitle title="Institucionalización"/>
            <div className='form-group'>
              <Input size="big" placeholder="Nombre del archivo"/>
              <Button color="#02a696">Descargar</Button>
            </div>
            <PageTitle title="Originalidad"/>
            <div className='gestion-proyecto-originalidad'>
              <span>¿El documento cumple con características de originalidad?</span>
              <div className='form-group'>
                <label for="si">
                  <input type="radio" id="si" name="original" value="si" onChange={handleOriginalChange}/>
                  Sí
                </label>
                <label for="no">
                  <input type="radio" id="no" name="original" value="no" onChange={handleOriginalChange}/>
                  No
                </label>
              </div>
            </div>
            {showAllForm && (
              <div className='container'>
                <PageTitle title="Asignar Jurados de Evaluación"/>
                <Info.Field label="Jurado 1" value={
                  <Select size="big" placeholder="Jurado seleccionado">
                    <option>Jurado 1</option>
                    <option>Jurado 2</option>
                    <option>Jurado 3</option>
                  </Select>
                } />

                <Info.Field label="Jurado 2" value={
                  <Select size="big" placeholder="Jurado seleccionado">
                    <option>Jurado 1</option>
                    <option>Jurado 2</option>
                    <option>Jurado 3</option>
                  </Select>
                } />

                <PageTitle title="Asignar Jurados de Evaluación"/>
                <Info.Field label="Fecha de sustentación" />
                <Info.Field label="Fecha" value={<Input type="date" size="big" />}/>
                <Info.Field label="Hora" value={<Input size="big" placeholder="XX:XX" />}/>
                <Info.Field label="Lugar" value={<Input size="big" placeholder="Link / Lugar" />}/>
              </div>
            )}
        </div>
    </div>
  );
}

export default ProyectoDetalle;