import './styles.scss';
import PageTitle from '../../../components/PageTitle';
import Info from '../../../components/Info';
import Button from '../../../components/Button';
import Table from '../../../components/Table';
import Input from '../../../components/Input';

const students = Array.from({ length: 5 });

const data = Array.from({ length: 10 }).map(() => ({
  aspecto: 'XXXX',
  comments: '',
  nota: '#'
}))


function EvaluacionDetalle() {  
  const columns = [
    {
      key: 'aspecto',
      label: 'Aspecto'
    },
    {
      key: 'comments',
      label: 'Comentarios',
      render: () => (
        <Input block placeholder="Comentario opcional" />
      )
    },
    {
      key: 'nota',
      label: 'Nota',
      render: (nota) => (
        <Input block placeholder={nota} />
      )
    }
  ]


  return (
    <div className='seguimiento-detalle'>
      <PageTitle title="Evaluación  " />
      <Info.Field block label="Título" value={
        <div className='identation'>
          Título del proyecto
        </div>
      } />
      <Info.Field block label="Estudiantes" value={
        <div className='seguimiento-estudiantes'>
        {students.map((_v, index) => (
          <ul key={String(index)}>
            <li>Nombre del estudiante 1</li>
            <li>Correo</li>
          </ul>
        ))}
        </div>
      }/>
      <Info.Field block label="Documento" value={
        <div className='evaluacion-documento'>
          <Button stroke="#02a696" color="#fff" size="big" block >Nombre del archivo</Button>
          <Button color="#02a696" size="big" block >Descargar</Button>
        </div>
      } />
      <Info.Field block label="Evaluación" value={
        <div className='identation'>
          <Table 
            columns={columns}
            data={data}
          />
          <div className='resumen-evaluacion'>
            <span>
            Nota final: 
            </span>
            <span>
              #
            </span>
          </div>
        </div>
      } />
    </div>
  );
}

export default EvaluacionDetalle;