import { useState } from 'react';
import './styles.scss';
import Button from '../../../components/Button';
import Block from '../../../components/Block';
import Modal from '../../../components/Modal';



import PageTitle from '../../../components/PageTitle';
import Info from '../../../components/Info';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Checkbox from '../../../components/Checkbox';
import { useNavigate } from 'react-router-dom';

function Propuesta() {
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const navigate = useNavigate();

  return (
    <div className='propuesta'>
      <PageTitle title="Inscripción Propuesta de Trabajo de Grado"/>
      <Block 
        title="Idea inicial de trabajo de grado"
        footer={<Button color="#02a696" onClick={() => setIsOpenConfirmModal(true)}>Guardar</Button>}
      >
        <Info.Group>
          <Info.Field size="small" label="Opción de grado" value={
            <Select>
              <option>Opcion 1</option>
              <option>Opcion 2</option>
              <option>Opcion 3</option>
            </Select>
          }/>
          <Info.Field size="small" label="Programa" value={
            <Input value="Ingeniería de sistemas y computación" readOnly/>
          }/>
        </Info.Group>

        <Info.Field block size="small" label="Descripción de la propuesta" value={
          <Input type="textarea" placeholder="Describa la propuesta inicial que tiene para su trabajo de grado, si no la tiene, escriba N/A" />
        } />
        
      
        <Info.Field size="small" label="Link del repositorio en el que almacena su documento" value={
          <Input placeholder="Link documento / carpeta en drive" block />
        } />

        <Info.Field size="small" label="Temática" block value={
          <div className='propuesta-checkbox-group'>
            <Checkbox label="Opción 1" />
            <Checkbox label="Opción 2" />
            <Checkbox label="Opción 3" />
            <Checkbox label="Opción 4" />
            <Checkbox label="Opción 5" />
            <Checkbox label="Opción 6" />
            <Checkbox label="Opción 7" />
            <Checkbox label="Opción 8" />
            <Checkbox label="Opción 9" />
            <Checkbox label="Otra" />
          </div>
        } />

      </Block>
      <div className='propuesta-group'>
        <Block 
          title="Compañeros de trabajo"
          footer={<Button color="#02a696" onClick={() => setIsOpenConfirmModal(true)}>Guardar</Button>}
          >
          <Info.Field label="¿Realizará trabajo en equipo?" size="small" block value={
            <div className='form-group'>
              <label for="si">
                <input type="radio" id="si" name="original" value="si" />
                Sí
              </label>
              <label for="no">
                <input type="radio" id="no" name="original" value="no" />
                No
              </label>
            </div>
          } />
          <Info.Field size="small" label="Nombre completo del compañero" value={
            <Input placeholder="Nombre del compañero" />
          } />
          <Info.Field size="small" label="Código" value={
            <Input placeholder="Código del compañero" />
          } />
        </Block>
        <Block 
          title="Opción de asesor" 
          footer={<Button color="#02a696" onClick={() => setIsOpenConfirmModal(true)}>Guardar</Button>}
        >
          <Info.Field label="Seleccione sus opcionados para ser su asesor de trabajo de grado" size="small" block value={
            <div>
              <Info.Field size="small" label="Opción 1" value={
                <Select>
                  <option>Opcion 1</option>
                  <option>Opcion 2</option>
                  <option>Opcion 3</option>
                </Select>
              } />
              <Info.Field size="small" label="Opción 2" value={
                <Select>
                  <option>Opcion 1</option>
                  <option>Opcion 2</option>
                  <option>Opcion 3</option>
                </Select>
              } />
              <Info.Field size="small" label="Opción 3" value={
                <Select>
                  <option>Opcion 1</option>
                  <option>Opcion 2</option>
                  <option>Opcion 3</option>
                </Select>
              } />
            </div>
          } />
        </Block>
      </div>
      <div className='acciones-propuesta'>
          <Button type="submit">Editar</Button>
          <Button type="submit" onClick={() => navigate('/estudiante/propuesta/confirmacion')}>Guardar</Button>
      </div>
      {isOpenConfirmModal && (
        <Modal 
            onClose={() => setIsOpenConfirmModal(false)}
            content={() => (
                <div className='confirm-message'>
                  !La información ha sido registrada!
                </div>
            )}
        />
        )}
    </div>
  );
}

export default Propuesta;