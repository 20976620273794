import React from 'react';
import './styles.scss';
import PageTitle from '../../../components/PageTitle';
import Table from '../../../components/Table';

const data = Array.from({ length: 10 }).map((_v, index) => ({
  aspecto: 'XXXXX',
  comment: 'Comentario Jurado 1',
  comment2: 'Comentario Jurado 2',
  note: '#',
  note2: '#',
}))



function EvaluacionNotas() {

  const columns = [
    {
      key: 'aspecto',
      label: 'Aspecto',
      style: { width: '100px'}
    },
    {
      key: 'comment',
      label: 'Comentario J1'
    },
    {
      key: 'comment2',
      label: 'Comentario J2'
    },
    {
      key: 'note',
      label: 'Nota J1'
    },
    {
      key: 'note2',
      label: 'Nota J2'
    },
  ]

  return (
    <div className='evaluacion'>
      <PageTitle title="Evaluación"/>
      <div className='container'>
        <PageTitle title={
          <>
            <span>Notas: </span>
            <span className='evaluacion-notas'>Si no está de acuerdo con su nota, por favor dirijase a secretaría académica para solicitar segundo evaluador</span>
          </>
        }/>
        
        <Table 
          columns={columns}
          data={data}
        />
        <table className='table-result'>
          <tr>
            <td>Total</td>
            <td>#</td>
            <td>#</td>
          </tr>
          <tr>
            <td>Definitiva</td>
            <td colSpan={2}>#</td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default EvaluacionNotas;