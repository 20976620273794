import { useState, useEffect } from 'react';
import './styles.scss';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import { IoMdCheckmark } from "react-icons/io";

function ModalConfirm({ text, confirmText, onConfirm, onClose, isOpen }) {

  const [openModal, setOpenModal] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  useEffect(() => {
    if (isOpen) {
        setOpenModal(true);
    }
  }, [isOpen])


  const handleOnClose = () => {
    if (onClose) onClose();
    setOpenModal(false);
  }

  const handleOnConfirm = () => {
    if (onConfirm) onConfirm();
    handleOnClose();
    setOpenModalConfirm(true);
  }

  return (
    <>
        {openModal && (
            <Modal
                onClose={handleOnClose}
                content={() => (
                    <div className="modal-content">
                        <div className='modal-text'>{text}</div>
                        <Button onClick={handleOnConfirm} color="#02a696">Sí</Button>
                    </div>
                )}
            />
        )}
        {openModalConfirm && (
            <Modal
                onClose={() => setOpenModalConfirm(false)}
                content={() => (
                    <div className="modal-content modal-content--notification">
                        <span className='circular-action'><IoMdCheckmark size="3rem"/></span>
                        <span className='modal-text'>{confirmText}</span>
                    </div>
                )}
            />
        )}
    </>
  );
}

export default ModalConfirm;