import { AiFillHome } from "react-icons/ai";
import { PiExamFill } from "react-icons/pi";
import { RiChatNewFill } from "react-icons/ri";
import { BiCurrentLocation } from "react-icons/bi";
import Home from '../pages/docente/Home';
import Propuestas from '../pages/docente/Propuestas';
import PropuestasDetalle from '../pages/docente/Propuestas/Detalle';

import Seguimiento from '../pages/docente/Seguimiento';
import SeguimientoDetalle from '../pages/docente/Seguimiento/Detalle';
import SeguimientoNota from '../pages/docente/Seguimiento/Nota';

import Evaluacion from "../pages/docente/Evaluacion";
import EvaluacionDetalle from "../pages/docente/Evaluacion/Detalle";

export const menu = [
    {
        title: "Inicio",
        icon:   <AiFillHome/>,
        link: "",
    },
    {
        title: "Propuestas",
        icon:   <RiChatNewFill/>,
        link: "propuestas"
    },
    {
        title: "Seguimiento",
        icon:   <BiCurrentLocation />,
        link: "seguimiento"
    },
    {
        title: "Evaluación",
        icon:   <PiExamFill />,
        link: "evaluacion"
    }
]

export const routes = [
    {
        path: "/",
        element: Home,
    },
    {
        path: "/propuestas",
        element: Propuestas,
    },
    {
        path: "/propuestas/detalle",
        element: PropuestasDetalle,
    },
    {
        path: "/seguimiento",
        element: Seguimiento,
    },
    {
        path: "/seguimiento/detalle",
        element: SeguimientoDetalle,
    },
    {
        path: "/seguimiento/nota",
        element: SeguimientoNota,
    },
    {
        path: "/evaluacion",
        element: Evaluacion,
    },
    {
        path: "/evaluacion/detalle",
        element: EvaluacionDetalle,
    },
]