import React from 'react';
import PageTitle from '../../../components/PageTitle';
import {draft_projects} from '../../../db/dummyProjects';
import Table from '../../../components/Table';
import Info from '../../../components/Info';
import './styles.scss';
import { useNavigate } from 'react-router-dom';

function teacher_name_search(teacher_id){}
function student_name_search(student_id){}

function Proyectos() {
  const navigate = useNavigate();

  const columns = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'name',
      label: 'Proyecto',
    },
    {
      key: 'is_active',
      label: 'Estado',
      render: (is_active) => is_active ? 'Activo': 'Inactivo'
    },
    {
      key: 'teacher_id',
      label: 'Asesor',
    },
    {
      label: 'Autores',
    }
  ]

  const onSearch = (search) => {
    console.log(search, 'search')
  }

  const onRowClick = (data) => {
    navigate(`/cordinacion/proyectos/${data.id}`);
 }

  return (
    <React.Fragment>
        <PageTitle title="Proyectos"/>
        <Info.Result label="Nº proyectos esperados" value="#"/>
        <Info.Result label="Nº proyectos activos" value="#"/>
        <Table
          columns={columns}
          data={draft_projects}
          onSearch={onSearch}
          onRowClick={onRowClick}
        />
    </React.Fragment>
  );
}

export default Proyectos;