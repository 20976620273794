import React from 'react';
import './styles.scss';
import Button from '../../../components/Button';
import { useNavigate } from "react-router-dom";
import PageTitle from '../../../components/PageTitle';

function Seguimiento() {

  const navigate = useNavigate();
  
  const handleClick = () => {
    navigate('/estudiante/seguimiento/finalizado')
  }

  return (
    <div className='seguimiento'>
      <PageTitle title="Seguimiento"/>
      <div className='seguimiento-group' onClick={handleClick}>
        <span className='seguimiento-circle'></span>
        <Button size="big" color="#02a696" >Tutoría dd-mm-aa</Button>
      </div>
      <div className='seguimiento-group' onClick={handleClick}>
        <span className='seguimiento-circle'></span>
        <Button size="big" color="#02a696" >Tutoría dd-mm-aa</Button>
      </div>
      <div className='seguimiento-group' onClick={handleClick}>
        <span className='seguimiento-circle'></span>
        <Button size="big" color="#02a696" >Tutoría dd-mm-aa</Button>
      </div>
      <div className='seguimiento-group' onClick={handleClick}>
        <span className='seguimiento-circle'></span>
        <Button size="big" color="#02a696" >Tutoría dd-mm-aa</Button>
      </div>
    </div>
  );
}

export default Seguimiento;