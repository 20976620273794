import React, {useState, useRef} from 'react';
import PageTitle from '../../../components/PageTitle';
import {teachers} from '../../../db/dummyTeach';
import Checkbox from '../../../components/Checkbox';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Modal from '../../../components/Modal';
import Info from '../../../components/Info';
import { useParams, useNavigate } from 'react-router-dom';

import './styles.css';
import Select from '../../../components/Select';


function GestDocente() {
    const messageRef = useRef(null);
    const { id } = useParams();
    const navigate = useNavigate();

    const teacher = teachers.find(item => String(item.id) === id);

    const [openAddModal, setOpenAddModal] = useState(false);
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

    const setOpenConfirmModal = (message) => {
        messageRef.current = message;
        setIsOpenConfirmModal(true);
    }

    const handleAdd = () => {
        setOpenAddModal(false);
        setOpenConfirmModal(<span>Proyecto añadido a <b>{teacher?.name} {teacher?.last_name}</b></span>)
    }

    const handleRemoveCard = () => {
        setOpenConfirmModal(<span>Proyecto eliminado de <b>{teacher?.name} {teacher?.last_name}</b></span>)
    }

    const handleClickCard = (id) => () => {
        navigate(`/cordinacion/proyectos/${id}`);
    }

  return (
    <div className='gestion-docente'>
        <PageTitle title="Gestión Docentes"/>
        <div className='container pt0'>
            <PageTitle title="Docente"/>
            <div className='gestion-docente-data'>
                <Info.Field label="Código" value={teacher?.id} />
                <Info.Field label="Estado" value={<Checkbox checked={teacher?.is_active} size="big" />} />
                <Info.Field label="Nombre Completo" value={`${teacher?.name} ${teacher?.last_name}`} />
                <Info.Field label="Nº horas" value={<Input defaultValue={teacher?.horas || 0} size="big" />} />
                <Info.Field label="Roles" value={
                    <>
                        <Checkbox size="big" checked={teacher?.es_asesor} label="Asesor" />
                        <Checkbox size="big" checked={teacher?.es_jurado} label="Jurado"/>
                    </>
                } />
                <div className='acciones'>
                    <Button type="submit">
                        Eliminar
                    </Button>
                    <div>
                        <Button type="submit">
                            Editar
                        </Button>
                        <Button type="submit">
                            Guardar
                        </Button>
                    </div>
                </div>
            </div>
            <div className='mt2'></div>
            <PageTitle title="Proyectos"/>
            <div className='gestion-docente-proyectos'>
                <h2>
                    Asistidos: #
                    <span className='btn-circular' onClick={() => setOpenAddModal(true)}>+</span>
                </h2>
                <div className='gestion-docente-seccion'>
                    {Array.from({ length: 6 }).map((_v, index) => (
                        <Card 
                            onClick={handleClickCard(index + 1)}
                            onClose={handleRemoveCard}
                            title="Título corto del proyecto"
                            content={() => (
                                <>
                                    <div>Nombre estudiante 1</div>
                                    <div>Nombre estudiante 2</div>
                                </>
                            )}
                        />
                    ))}
                </div>
                <h2>De Jurado: #</h2>
                <div className='gestion-docente-seccion'>
                    {Array.from({ length: 6 }).map((_v, index) => (
                        <Card 
                            onClick={handleClickCard(index + 1)}
                            onClose={handleRemoveCard}
                            title="Título corto del proyecto"
                            content={() => (
                                <>
                                    <div>Nombre estudiante 1</div>
                                    <div>Nombre estudiante 2</div>
                                </>
                            )}
                        />
                    ))}
                </div>
            </div>
        </div>
        {openAddModal && (
            <Modal 
                onClose={() => setOpenAddModal(false)}
                content={() => (
                    <>
                        <div className='form-group form-group--big'>
                            <Select size="big" placeholder="Seleccionar proyecto">
                                <option>Código | Título</option>
                                <option>Código | Título</option>
                                <option>Código | Título</option>
                                <option>Código | Título</option>
                                <option>Código | Título</option>
                            </Select>
                        </div>
                        <Button 
                            block 
                            onClick={handleAdd}
                            color="#F9A825" 
                            size="big"
                        >
                            Guardar
                        </Button>
                    </>
                )}
            />
        )}
        {isOpenConfirmModal && (
            <Modal 
                onClose={() => setIsOpenConfirmModal(false)}
                content={() => (
                    <div className='confirm-message'>
                        {messageRef.current}
                    </div>
                )}
            />
        )}
    </div>
  );
}

export default GestDocente;