import React, { useRef, useState } from 'react';
import './style.scss';

function File({ label, defaultValue, id: defaultId, onChange, ...inputProps }, ref) {
    const inputRef = useRef(null);
    const id = defaultId || `input-${Math.random()}`;

    const [filename, setFilename] = useState('Sin archivos seleccionados ...');

    const handleOnChange = (e) => {
        const file = inputRef.current?.files[0];
        setFilename(file?.name);
        if (onChange) onChange(e, file);
    }

    return (
        <label for={`${id}`} className="file-container">{label}
            <input ref={inputRef} defaultValue={defaultValue} {...inputProps} type="file" onChange={handleOnChange} id={id} />
            <button className='file-button overflow-ellipsis'>Seleccionar archivo</button>
            <span className='file-loaded overflow-ellipsis'>{filename}</span>
        </label>
    )
}

export default React.forwardRef(File);
