import React from 'react';
import PageTitle from '../../../components/PageTitle';
import Info from '../../../components/Info';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import { useParams, useNavigate } from 'react-router-dom';

import './styles.css';


function EstudianteDetalle() {
    const navigate = useNavigate();
    const { id } = useParams();
    console.log(id, 'id del estudiante')

    const handleShowProject = () => {
        const idProyecto = 1
        navigate(`/cordinacion/proyectos/${idProyecto}`)
    }

  return (
    <div className='gestion-estudiante'>
        <PageTitle title="Gestión Estudiantes"/>
        <div className='container pt0'>
            <PageTitle title="Estudiante"/>
            <div>
              <Info.Group>
                <Info.Field label="Código" value="XXXXX" />
                <Button className="mb1" type="submit" onClick={handleShowProject}>Ver Proyecto</Button>
              </Info.Group>
              <Info.Field label="Estado" value={<Checkbox size="big" />} />  
              <Info.Field label="Nombre completo" value="Nombre del estudiante" />  
              <Info.Field label="Paso actual" value="Paso dentro del proceso" /> 
              <Info.Field label="Estado del paso" value="Estado" />  
            </div>
            <PageTitle title="Proyecto"/>
            <div>
              <Info.Field label="Código" value="XXXXX" />
              <Info.Field label="Título" value="Título del proyecto" />  
              <Info.Field label="Asesor" value="Nombre del asesor" />  
              <Info.Field label="Descripción" value="Pequeña descripción del proyecto" />
            </div>
            <div className='acciones'>
                <Button type="submit">
                    Eliminar
                </Button>
                <div>
                    <Button type="submit">
                        Editar
                    </Button>
                    <Button type="submit">
                        Guardar
                    </Button>
                </div>
            </div>
        </div>
    </div>
  );
}

export default EstudianteDetalle;