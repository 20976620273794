export const students = [
  {
    "code": 670001,
    "draft_project_id": 1,
    "id": 1,
    "email": "student1@example.com",
    "name": "Juan",
    "last_name": "Gómez",
    "is_active": true,
    "password": "contraseña123",
    "confirmation": true
  },
  {
    "code": 670002,
    "draft_project_id": 2,
    "id": 2,
    "email": "student2@example.com",
    "name": "María",
    "last_name": "Rodríguez",
    "is_active": true,
    "password": "secreto456",
    "confirmation": true
  },
  {
    "code": 670003,
    "draft_project_id": 3,
    "id": 3,
    "email": "student3@example.com",
    "name": "Luis",
    "last_name": "Pérez",
    "is_active": false,
    "password": "clave789",
    "confirmation": false
  },
  {
    "code": 670004,
    "draft_project_id": 4,
    "id": 4,
    "email": "student14@example.com",
    "name": "Ana",
    "last_name": "Martínez",
    "is_active": true,
    "password": "segura123",
    "confirmation": true
  },
  {
    "code": 670005,
    "draft_project_id": 5,
    "id": 5,
    "email": "student5@example.com",
    "name": "Carlos",
    "last_name": "López",
    "is_active": true,
    "password": "protegida456",
    "confirmation": true
  },
  {
    "code": 670006,
    "draft_project_id": 1,
    "id": 6,
    "email": "student6@example.com",
    "name": "Sofía",
    "last_name": "González",
    "is_active": false,
    "password": "secreta789",
    "confirmation": false
  },
  {
    "code": 670007,
    "draft_project_id": 2,
    "id": 7,
    "email": "student7@example.com",
    "name": "Pedro",
    "last_name": "Hernández",
    "is_active": true,
    "password": "clave123",
    "confirmation": true
  },
  {
    "code": 670008,
    "draft_project_id": 3,
    "id": 8,
    "email": "student8@example.com",
    "name": "Laura",
    "last_name": "Díaz",
    "is_active": true,
    "password": "seguridad456",
    "confirmation": true
  },
  {
    "code": 670009,
    "draft_project_id": 4,
    "id": 9,
    "email": "student9@example.com",
    "name": "Javier",
    "last_name": "Ramírez",
    "is_active": false,
    "password": "contraseña789",
    "confirmation": false
  },
  {
    "code": 670010,
    "draft_project_id": 5,
    "id": 10,
    "email": "student10@example.com",
    "name": "Elena",
    "last_name": "Sánchez",
    "is_active": true,
    "password": "secreto123",
    "confirmation": true
  },
  {
    "code": 670011,
    "draft_project_id": 1,
    "id": 11,
    "email": "student11@example.com",
    "name": "Miguel",
    "last_name": "López",
    "is_active": true,
    "password": "password11",
    "confirmation": true
  },
  {
    "code": 670012,
    "draft_project_id": 2,
    "id": 12,
    "email": "student12@example.com",
    "name": "Isabel",
    "last_name": "García",
    "is_active": false,
    "password": "password12",
    "confirmation": false
  },
  {
    "code": 670013,
    "draft_project_id": 3,
    "id": 13,
    "email": "student13@example.com",
    "name": "Pedro",
    "last_name": "Santos",
    "is_active": true,
    "password": "password13",
    "confirmation": true
  },
  {
    "code": 670014,
    "draft_project_id": 4,
    "id": 14,
    "email": "student14@example.com",
    "name": "Marta",
    "last_name": "Martínez",
    "is_active": true,
    "password": "password14",
    "confirmation": true
  },
  {
    "code": 670015,
    "draft_project_id": 5,
    "id": 15,
    "email": "student15@example.com",
    "name": "David",
    "last_name": "Pérez",
    "is_active": false,
    "password": "password15",
    "confirmation": false
  },
  {
    "code": 670016,
    "draft_project_id": 1,
    "id": 16,
    "email": "student16@example.com",
    "name": "Sara",
    "last_name": "López",
    "is_active": true,
    "password": "password16",
    "confirmation": true
  },
  {
    "code": 670017,
    "draft_project_id": 2,
    "id": 17,
    "email": "student17@example.com",
    "name": "Carlos",
    "last_name": "González",
    "is_active": true,
    "password": "password17",
    "confirmation": true
  },
  {
    "code": 670018,
    "draft_project_id": 3,
    "id": 18,
    "email": "student18@example.com",
    "name": "Luisa",
    "last_name": "Hernández",
    "is_active": false,
    "password": "password18",
    "confirmation": false
  },
  {
    "code": 670019,
    "draft_project_id": 4,
    "id": 19,
    "email": "student19@example.com",
    "name": "Diego",
    "last_name": "Sánchez",
    "is_active": true,
    "password": "password19",
    "confirmation": true
  },
  {
    "code": 670020,
    "draft_project_id": 4,
    "id": 20,
    "email": "student20@example.com",
    "name": "Steve",
    "last_name": "Jobs",
    "is_active": true,
    "password": "password19",
    "confirmation": true
  }
]