import { useState } from 'react';
import './styles.scss';
import PageTitle from '../../../components/PageTitle';
import Info from '../../../components/Info';
import Button from '../../../components/Button';
import Input from '../../../components/Input';



function SeguimientoNota() {

  const [totalCompromisos, setTotalCompromisos] = useState(1);
  
  const compromisos = Array.from({ length: totalCompromisos });

  return (
    <div className='seguimiento-nota'>
      <PageTitle title="Seguimiento" />
      <div className='container'>
        <PageTitle title="Compromisos" />
        <Info.Field size="medium" label="Fecha" block value={
          <div className='identation'>
            <Button size="big" color="#02a696">Tutoría dd mm aa</Button>
          </div>
        }/>
        <Info.Field size="medium" label="Compromisos" block value={
          <div className='agregar-compromisos'>
            <div className='todos-compromisos'>
              {compromisos.map((_v, index) => (
                <Input key={String(index)} block type="textarea" placeholder="Describir el compromiso pactado"/>
              ))}
            </div>
            <Button onClick={() => setTotalCompromisos(m => m+1)} size="big" color="#000">+</Button>
          </div>
        }/>
        <div className='acciones'>
          <Button type="submit">
              Eliminar
          </Button>
          <div>
              <Button type="submit">
                  Editar
              </Button>
              <Button type="submit">
                  Guardar
              </Button>
          </div>
      </div>
      </div>
    </div>
  );
}

export default SeguimientoNota;