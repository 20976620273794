import React from 'react';
import './styles.css'
import PageTitle from '../../../components/PageTitle';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';

function Comunicados() {
  const navigate = useNavigate();
  return (
    <div className='comunicados'>
        <PageTitle title="Comunicaciones"/>
        <div className='submenu-comunicados'>
          <Button 
            color="#5DADEC"
            onClick={() => navigate('/cordinacion/comunicados/reunion-informativa')}
          >
            Reunión informativa
          </Button>
          <Button 
            color="#03A696"
            onClick={() => navigate('/cordinacion/comunicados/anuncios-generales')}
          >
            Anuncios generales
          </Button>
          <Button 
            color="#FF9470"
            onClick={() => navigate('/cordinacion/comunicados/fechas-importantes')}
          >
            Fechas importantes
          </Button>
        </div>
    </div>
  );
}

export default Comunicados;