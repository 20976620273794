import { useState } from 'react';
import './styles.scss';
import PageTitle from '../../../components/PageTitle';
import Info from '../../../components/Info';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { RxCross2 } from "react-icons/rx";
import { IoMdCheckmark } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

const students = Array.from({ length: 5 });

function SeguimientoDetalle() {

  const navigate = useNavigate();
  const [modalSend, setModalSend] = useState(false);
  const [modalTutoria, setModalTutoria] = useState(false);
  

  return (
    <div className='seguimiento-detalle'>
      <PageTitle title="Propuestas" />
      <Info.Field label="Título corto" value="Título del proyecto" />
      <Info.Field block label="Estudiantes" value={
        <div className='seguimiento-estudiantes'>
        {students.map((_v, index) => (
          <ul key={String(index)}>
            <li>Nombre del estudiante 1</li>
            <li>Correo</li>
          </ul>
        ))}
        </div>
      }/>
      <Info.Field block label="Tutorías realizadas" value={
        <div className='tutorias-realizadas'>
          <Button size="big" block onClick={() => setModalTutoria(true)}>Tutoría dd mm aa</Button>
          <Button size="big" block onClick={() => setModalTutoria(true)}>Tutoría dd mm aa</Button>
          <Button onClick={() => navigate('/docente/seguimiento/nota')} className="btn-nueva-nota" size="big" block>Nueva nota</Button>
        </div>
      } />
      <Info.Field block label="Institucionalización" value={
        <div className='seguimiento-institucionalizacion'>
          <Button size="big" >Nombre del archivo</Button>
          <a href="#">Link del documento</a>
        </div>
      } />
      <div className='acciones'>
        <Button type="submit" size="big" block>Descargar</Button>
        <Button onClick={() => setModalSend(true)} type="submit" size="big" block>Enviar a coordinación</Button>
      </div>
      {modalSend && (
        <Modal 
          onClose={() => setModalSend(false)}
          content={() => <span>¡La información se ha enviado a coordinación!</span>}
        />
      )}

      {modalTutoria && (
        <Modal 
          title={<PageTitle title="Proyecto" />}
          onClose={() => setModalTutoria(false)}
          content={() => (
            <div>
              <div className='tutorias-realizadas'>
                <Button size="big">Tutoría dd mm aa</Button>
                  <div className="modal-content modal-content--notification">
                    <span className='circular-action'><IoMdCheckmark size="3rem"/></span>
                    <span className='modal-text'>Compromiso pactado / cumplido o no</span>
                  </div>
                  <div className="modal-content modal-content--notification">
                    <span className='circular-action circular-action--reject'><RxCross2 size="3rem"/></span>
                    <span className='modal-text'>Compromiso pactado / cumplido o no</span>
                  </div>
                  <div className="modal-content modal-content--notification">
                    <span className='circular-action'><IoMdCheckmark size="3rem"/></span>
                    <span className='modal-text'>Compromiso pactado / cumplido o no</span>
                  </div>
                </div>
            </div>
          )}
        />
      )}
    </div>
  );
}

export default SeguimientoDetalle;