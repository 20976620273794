import React from 'react';
import './styles.scss';
import Button from '../../../components/Button';
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  return (
    <>
      <div className='dates'>
        <h1>Fechas importantes</h1>
        <div className='dates-content'>
          <div className='dates-item'>
            <span className='dates-item-description'>Descripción</span>
            <span className='dates-item-date'>dd-mm-aa</span>
          </div>
          <div className='dates-item'>
            <span className='dates-item-description'>Descripción</span>
            <span className='dates-item-date'>dd-mm-aa</span>
          </div>
          <div className='dates-item'>
            <span className='dates-item-description'>Descripción</span>
            <span className='dates-item-date'>dd-mm-aa</span>
          </div>
        </div>
        <div className='docente-inicio'>
          <Button 
            onClick={() => navigate('/docente/propuestas') }
            size="big" 
            color="#5eadeb"
          >
            Propuestas de trabajo de grado
          </Button>
          <Button 
            onClick={() => navigate('/docente/seguimiento') }
            size="big" 
            color="#02a696"
          >
            Seguimiento de trabajo de grado
          </Button>
          <Button 
            onClick={() => navigate('/docente/evaluacion') }
            size="big" 
            color="#ff946f"
          >
            Evaluación de trabajo de grado
          </Button>
        </div>
      </div>
    </>
  );
}

export default Home;