import React from 'react';
import './styles.css'
import PageTitle from '../../../components/PageTitle';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Button from '../../../components/Button';

function ComunicadosAnuncios() {
  return (
    <div className='comunicados'>
        <PageTitle title="Comunicaciones"/>
        <div className='container pt0'>
            <PageTitle title="Anuncios generales"/>
            <div className='form-group form-group--big'>
                <Input
                    size="big"
                    label="Título"
                    type="text"
                    name="titulo"
                    placeholder="Título"
                />
                <Select
                    size="big"
                    label="Para"
                    type="text"
                    name="para"
                >
                    <option value="general" >General</option>
                    <option value="estudiantes" >Estudiantes</option>
                    <option value="docentes" >Docentes</option>
                </Select>
            </div>
            <div className='form-group form-group--big'>
                <Input
                    size="big"
                    label="Mensaje"
                    type="textarea"
                    name="mensaje"
                    placeholder="Mensaje pre definido con los datos anteriores"
                />
            </div>
            <div className='acciones'>
                <Button type="submit">
                    Eliminar
                </Button>
                <div>
                    <Button type="submit">
                        Editar
                    </Button>
                    <Button type="submit">
                        Guardar
                    </Button>
                </div>
            </div>
        </div>
    </div>
  );
}

export default ComunicadosAnuncios;