import { useState } from 'react';
import './styles.scss';
import classnames from 'classnames';
import Block from '../../../components/Block';
import Info from '../../../components/Info';
import PageTitle from '../../../components/PageTitle';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Checkbox from '../../../components/Checkbox';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';


function Institucionalizacion() {

  const [totalAuthors, setTotalAuthors] = useState(1);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [step, setStep] = useState(0);

  const finished = step === 2;
  const handleAddAuthor = () => {
    setTotalAuthors(t => t+1)
  }
  
  return (
    <div className='institucionalizacion'>
      <PageTitle title="institucionalizacion"/>
      <Block 
        collapsable
        title="Generalidades" 
        footer={
          <>
            <Button color="#02a696">Editar</Button>
            <Button color="#02a696" onClick={() => setIsOpenConfirmModal(true)}>Guardar</Button>
          </>
        }
      >
        <Info.Field block size="small" label="Título" value={
          <Input block placeholder="Título del trabajo de grado" />
        } />
        <Info.Group>
          <Info.Field block size="small" label="Opción de grado" value={
            <Select>
              <option>Opción 1</option>
              <option>Opción 2</option>
              <option>Opción 3</option>
            </Select>
          } />
          <Info.Field block size="small" label="Programa" value={
            <Input placeholder="Ingeniería de sistemas y computación" />
          } />
        </Info.Group>
        <Info.Field block size="small" label="Resumen" value={
            <Input type="textarea" placeholder="Resumen de su trabajo de grado" />
          } />
          <Info.Field size="small" label="Temática" block value={
          <div className='propuesta-checkbox-group'>
            <Checkbox label="Opción 1" />
            <Checkbox label="Opción 2" />
            <Checkbox label="Opción 3" />
            <Checkbox label="Opción 4" />
            <Checkbox label="Opción 5" />
            <Checkbox label="Opción 6" />
            <Checkbox label="Opción 7" />
            <Checkbox label="Opción 8" />
            <Checkbox label="Opción 9" />
            <Checkbox label="Otra" />
          </div>
        } />
      </Block>
      <Block 
        collapsable
        title="Autores" 
        footer={
          <>
            <Button color="#02a696">Editar</Button>
            <Button color="#02a696" onClick={() => setIsOpenConfirmModal(true)}>Guardar</Button>
          </>
        }>
        <Button onClick={handleAddAuthor} type="submit" size="big" className="autores-add">+</Button>
        {Array.from({ length: totalAuthors }).map((_v, index) => (
          <div className='autores-group' key={String(index)}>
            <Info.Field  size="small" label="Nombre" value={
              <Input block />
            } />
            <Info.Field  size="small" label="Cargo" value={
              <Input block />
            } />
            <Info.Field size="small" label="Código" value={
              <Input block />
            } />
          </div>
        ))}
      </Block>
      <Block 
        collapsable
        title="Opción de asesor" 
        footer={
          <>
            <Button color="#02a696">Editar</Button>
            <Button color="#02a696" onClick={() => setIsOpenConfirmModal(true)}>Guardar</Button>
          </>
        }>
          <div className='institucionalizacion-asesor'>
            <Info.Field block size="small" label="Seleccione su asesor" value={
                <Select block >
                <option>Opción 1</option>
                <option>Opción 2</option>
                <option>Opción 3</option>
              </Select>
            } />
          </div>
      </Block>
      <Block 
        collapsable
        title="Estado"
      >
      <div className='fases'>
        {finished ? <h2 className='fase-terminada'>Proceso terminado</h2>: <h2>Proceso en curso</h2>}
        <h3>Estado:</h3>
        <div className='fases-container'>
          <div className='fases-grupo' onClick={() => setStep(0)}>
            <span className={classnames('fase-circulo', {
              'fase-circulo--active': step >= 0
            })}></span>
            <span className='fase-texto'>Fase 1. Estudiante</span>
          </div>
          <div className='fases-grupo' onClick={() => setStep(1)}>
          <span className={classnames('fase-circulo', {
              'fase-circulo--active': step >= 1
            })}></span>
            <span className='fase-texto'>Fase 2. Asesor</span>
          </div>
          <div className='fases-grupo' onClick={() => setStep(2)}>
          <span className={classnames('fase-circulo', {
              'fase-circulo--active': step >= 2
            })}></span>
            <span className='fase-texto'>Fase 3. Coordinador</span>
          </div>
        </div>
        {finished ? (
          <div className='fase-descripcion--finalizada'>
              Espere la notificación del evaluador
          </div>
        ) : (
          <div className='fase-descripcion--curso'>
              Descripción actual del estado del proceso
          </div>
        )}
      </div>
      </Block>
      <div className='acciones'>
        <Button type="submit">
            Eliminar
        </Button>
        <div>
            <Button type="submit">
                Editar
            </Button>
            <Button type="submit">
                Guardar
            </Button>
        </div>
    </div>
      {isOpenConfirmModal && (
        <Modal 
            onClose={() => setIsOpenConfirmModal(false)}
            content={() => (
                <div className='confirm-message'>
                  !La información ha sido registrada!
                </div>
            )}
        />
        )}
    </div>
  );
}

export default Institucionalizacion;