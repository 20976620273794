import React, { useState } from 'react';
import { BiSolidUser } from 'react-icons/bi'
import Logo from '../../assets/img/logo.png'
import './styles.scss';
import Step1 from '../../assets/img/Step_1.png'
import Step2 from '../../assets/img/Step_2.png'
import Step3 from '../../assets/img/Step_3.png'
import Step4 from '../../assets/img/Step_4.png'

function Home() {
  return (
    <div className='home'>
      <header className='home-header'>
        <div className='home-header-content'>
          <img src={Logo} alt="logo" />
          <div>
            <h1>SIGETG</h1>
            <span>Sistema de Gestión de Trabajos de Grado</span>
          </div>
        </div>
        <Menu />
      </header>
      <main>
        <div className='home-section'>
          <h2 className='home-title'>Calendario:</h2>
          <div className='home-content'>
            <CalendarItem month="# Mes" year="año" description="Descripción"/>
            <CalendarItem month="# Mes" year="año" description="Descripción"/>
            <CalendarItem month="# Mes" year="año" description="Descripción"/>
            <CalendarItem month="# Mes" year="año" description="Descripción"/>
          </div>
        </div>
        <div className='home-section'>
          <h2 className='home-title'>Noticias</h2>
          <div className='home-content'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.
          </div>
        </div>
        <div className='home-section'>
          <h2 className='home-title'>Proceso</h2>
          <div className='home-content'>
            <Process_Step image={Step1}/>
            <Process_Step image={Step2}/>
            <Process_Step image={Step3}/>
            <Process_Step image={Step4}/>
          </div>
          *Los estudiantes deben pasar la validación de originalidad, la autorización del asesor y la evaluación de Jurados para continuar con el proceso
        </div>
      </main>
    </div>
  )
}

function CalendarItem ({ month, year, description }) {
  return (
    <div className='home-calendar-item'>
      <span className='home-calendar-month'>{month}</span>
      <span className='home-calendar-year'>{year}</span>
      <span className='home-calendar-description'>{description}</span>
    </div>
  )
}

function Menu () {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <div className='home-avatar' onClick={() => setOpenMenu(o => !o)}>
      <BiSolidUser />
      <nav className={`home-menu ${openMenu ? 'home-menu--open' : ''}`}>
        <ul>
          <li><a href="/login?tipo=estudiante">Soy Estudiante</a></li>
          <li><a href="/login?tipo=docente">Soy Docente</a></li>
          <li><a href="/login?tipo=cordinacion">Soy Coordinador</a></li>
        </ul>
      </nav>
    </div>
  )
}

function Process_Step({image}){
  return(
    <div className='home_steps'>
    <img src={image} alt="process_Step" />
    </div>
  )
}

export default Home;
