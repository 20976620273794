import './styles.scss';

function Card ({ title, content, onClose, onClick, color }) {
    const handleOnClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onClick) onClick();
    }
    return (
        <div className="card" style={{ backgroundColor: color }}>
            {onClose && <button onClick={onClose} className="btn-circular card-close">x</button>}
            <div className="card-container" onClick={handleOnClick}>
                <div className="card-header">{title}</div>
                <div className="card-body">{content()}</div>
            </div>
        </div>
    )
}

export default Card;