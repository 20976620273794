import React from 'react';
import classnames from 'classnames'
import './style.scss';

function Select({ label, placeholder, size, inline, defaultValue, id: defaultId, onChange, children, ...selectProps }, ref) {

    const id = defaultId || `input-${Math.random()}`;
    const handleOnChange = (e) => {
        if (onChange) onChange(e.target.value);
    }

    return (
        <label for={`${id}`} className={classnames("select-container", {
            'select--inline': !!inline,
            [`select--${size}`]: !!size
        })}>
            {label && <span className='select-label'>{label}</span>}
            <select ref={ref} defaultValue={defaultValue} {...selectProps} onChange={handleOnChange} id={id}>
                <option>{placeholder ? placeholder : 'Seleccionar ...'}</option>
                {children}
            </select>
        </label>
    )
}

export default React.forwardRef(Select);
