import { useState, useEffect } from 'react';
import './styles.scss';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import { RxCross2 } from "react-icons/rx";

function ModalReject({ text, confirmText, onReject, onClose, isOpen }) {

  const [openModal, setOpenModal] = useState(false);
  const [openModalReject, setOpenModalReject] = useState(false);

  useEffect(() => {
    if (isOpen) {
        setOpenModal(true);
    }
  }, [isOpen])


  const handleOnClose = () => {
    if (onClose) onClose();
    setOpenModal(false);
  }

  const handleOnReject = () => {
    if (onReject) onReject();
    handleOnClose();
    setOpenModalReject(true);
  }

  return (
    <>
        {openModal && (
            <Modal
                onClose={handleOnClose}
                content={() => (
                    <div className="modal-content">
                        <div className='modal-text'>{text}</div>
                        <Button onClick={handleOnReject} color="#de4a4b">Sí</Button>
                    </div>
                )}
            />
        )}
        {openModalReject && (
            <Modal
                onClose={() => setOpenModalReject(false)}
                content={() => (
                    <div className="modal-content modal-content--notification">
                        <span className='circular-action circular-action--reject'><RxCross2 size="3rem"/></span>
                        <span className='modal-text'>{confirmText}</span>
                    </div>
                )}
            />
        )}
    </>
  );
}

export default ModalReject;