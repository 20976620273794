import React from 'react';
import './styles.css'
import PageTitle from '../../../components/PageTitle';
import Input from '../../../components/Input';
import Button from '../../../components/Button';

function ComunicadosFechas() {
  return (
    <div className='comunicados'>
        <PageTitle title="Comunicaciones"/>
        <div className='container pt0'>
            <PageTitle title="Fechas importantes"/>
            <div className='form-group form-group--big'>
                <Input
                    size="big"
                    label="Fecha"
                    type="date"
                    name="fecha"
                    placeholder="aaaa"
                />
                <Input
                    size="big"
                    label="Hora"
                    type="text"
                    name="hora"
                    placeholder="hh:mm"
                />
            </div>
            <div className='form-group form-group--big'>
                <Input
                    size="big"
                    label="Mensaje"
                    type="textarea"
                    name="mensaje"
                    placeholder="Mensaje pre definido con los datos anteriores"
                />
            </div>
            <div className='acciones'>
                <Button type="submit">
                    Eliminar
                </Button>
                <div>
                    <Button type="submit">
                        Editar
                    </Button>
                    <Button type="submit">
                        Guardar
                    </Button>
                </div>
            </div>
        </div>
    </div>
  );
}

export default ComunicadosFechas;