import React from 'react';
//Estilos generales

//Navegación
import { BrowserRouter, Routes, Route, useLocation} from 'react-router-dom';
import { Navigate } from 'react-router-dom';

//Paginas publicas
import Panelcontrol from "./components/Panelcontrol";
import Login from './pages/Login';
import Home from './pages/Home';
import * as router from './router'


const PrivateRoutes = () => {
  const location = useLocation()
  const baseLocation = String(location.pathname).split('/')[1];
  const permisos = localStorage.getItem('permisos');

  if (!permisos) {
    return <Navigate to='/' />
  }

  if (baseLocation !== permisos) {
    return <Navigate to={`/${permisos}`} />
  }

  const { routes, menu } = router[permisos];

  return (
    <div className='main-container'>
      <Panelcontrol menu={menu} permisos={permisos}>
        <Routes>
          {routes.map(({ element: Component, path}) => (
            <Route path={path} element={<Component/>}/>
          ))}
        </Routes>
      </Panelcontrol>
    </div>
  )
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/login" element={<Login/>}/>
        <Route path="/cordinacion/*" element={<PrivateRoutes />}/>
        <Route path="/docente/*" element={<PrivateRoutes />}/>
        <Route path="/estudiante/*" element={<PrivateRoutes />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
