import { useState } from 'react';
import './styles.scss';
import Info from '../../../components/Info';
import PageTitle from '../../../components/PageTitle';
import Button from '../../../components/Button';
import ModalConfirm from './ModalConfirm';
import ModalReject from './ModalReject';


function PropuestasDetalle() {

    const [openConfirm, setOpenConfirm] = useState(false);
    const [openReject, setOpenReject] = useState(false);
  
  return (
        <div className='propuestas'>
            <PageTitle title="Propuestas"/>
            <div className='container'>
                <Info.Field 
                    block 
                    size="medium"
                    label="Descripción de la propuesta" 
                    value={
                        <div className='propuestas-simple-text'>
                            Descripción realizada por los estudiantes
                        </div>
                    }
                />

                <Info.Field 
                    block 
                    size="medium"
                    label="Opción de trabajo de grado" 
                    value={
                        <div className='propuestas-simple-text'>
                           Se muestra la opción elegida por los estudiantes
                        </div>
                    }
                />

                <Info.Field 
                    block 
                    size="medium"
                    label="Temáticas" 
                    value={
                        <div className='propuestas-simple-text'>
                           <ul>
                                <li>Temática principal</li>
                                <li>Temática opción 2</li>
                                <li>Temática opción 3</li>
                           </ul>
                        </div>
                    }
                />

                <Info.Field 
                    block 
                    size="medium"
                    label="Estudiantes" 
                    value={
                        <div className='propuestas-simple-text'>
                           <ul>
                                <li>Estudiante 1</li>
                                <li>Correo</li>
                           </ul>
                           <br />
                           <ul>
                                <li>Estudiante 2</li>
                                <li>Correo</li>
                           </ul>
                        </div>
                    }
                />

                <div className='acciones'>
                    <Button onClick={() => setOpenConfirm(true)} block color="#02a696" size="big">Aceptar</Button>
                    <Button onClick={() => setOpenReject(true)}  block color="#de4a4b" size="big">Negar</Button>
                </div>
                <ModalConfirm
                    isOpen={openConfirm}
                    onClose={() => setOpenConfirm(false)}
                    confirmText="!Propuesta aceptada!"
                    text={<span>¿Estás seguro de <b>aceptar</b> ser tutor de esta propuesta?</span>}
                />
                <ModalReject
                    isOpen={openReject}
                    onClose={() => setOpenReject(false)}
                    confirmText="!Propuesta rechazada!"
                    text={<span>¿Estás seguro de <b>rechazar</b> ser tutor de esta propuesta?</span>}
                />
            </div>
        </div>
    )
}

export default PropuestasDetalle;