import React from 'react';
import './styles.scss';
import PageTitle from '../../../components/PageTitle';
import Table from '../../../components/Table';
import { useNavigate } from "react-router-dom";
import Button from '../../../components/Button';


const data = Array.from({ length: 10 }).map((_v, index) => ({
  id_rubrica: index,
  aspecto: 'XXXXX',
  descriprion: 'Descripcion aspecto',
  percent: Math.floor(Math.random() * 100)
}))



function Evaluacion() {
  const navigate = useNavigate();

  const handleNotasClick = () => {
    navigate('/estudiante/evaluacion/notas')
  }

  const columns = [
    {
      key: 'id_rubrica',
      label: 'Id'
    },
    {
      key: 'aspecto',
      label: 'Aspecto'
    },
    {
      key: 'descriprion',
      label: 'Descripción'
    },
    {
      key: 'percent',
      label: '%'
    },
  ]

  return (
    <div className='evaluacion'>
      <PageTitle title="Evaluación"/>
      <Button onClick={handleNotasClick} className="evaluacion-ver-nota" type="submit" size="big">Ver nota</Button>
      <div className='container'>
        <PageTitle title="Socialización"/>
        <div className='container pt0'>
          <div className='socializacion-item'>
            <span>Lugar</span>
            <div className='socializacion-fecha'>
              <span>dd-mm-aa</span>
              <span>xx:xx x.m</span>
            </div>
          </div>
        </div>
        <PageTitle title="Rúbrica"/>
        <Table 
          columns={columns}
          data={data}
        />
        <table className='table-result'>
          <tr>
            <td>Total</td>
            <td>#</td>
            <td>#</td>
          </tr>
          <tr>
            <td>Definitiva</td>
            <td colSpan={2}>#</td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default Evaluacion;