import './style.scss';
import classnames from 'classnames';

function Button({ type, children, onClick, size, block, color, className, stroke }) {

    const strokeStyles = stroke ? { border: `1px solid ${stroke}`, color: stroke } : {}
    
    return (
        <button
            type={type} 
            onClick={onClick} 
            style={{ backgroundColor: color, ...strokeStyles }}
            className={classnames('button', className, {
                [`button--${type}`]: !!type, 
                [`button--${size}`]: !!size,
                [`button--block`]: !!block,
            })}
        >
            {children}
        </button>
    )
}

export default Button;
