import React from 'react';
import classnames from 'classnames';
import './style.scss';

function Input({ label, inline, size, icon, defaultValue, id: defaultId, onChange, ...inputProps }, ref) {

    const id = defaultId || `input-${Math.random()}`;
    const handleOnChange = (e) => {
        if (onChange) onChange(e.target.value);
    }

    return (
        <label for={`${id}`} className={classnames("input-container", {
            'input--inline': !!inline,
            [`input--${size}`]: !!size
        })}>
            {label && <span className='input-label'>{label}</span>}
            {icon && <div className='input-icon'>{icon}</div>}
            {inputProps.type !== 'textarea' ? (
                <input ref={ref} defaultValue={defaultValue} {...inputProps} onChange={handleOnChange} id={id} />
            ) : (
                <textarea ref={ref} onChange={handleOnChange} id={id} {...inputProps}>{defaultValue}</textarea>
            )}
            </label>
    )
}

export default React.forwardRef(Input);
