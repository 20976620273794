import './styles.scss';
import PageTitle from '../../../components/PageTitle';
import Table from '../../../components/Table';
import Input from '../../../components/Input';


const data = Array.from({ length: 10 }).map(() => ({
    aspecto: 'XXXXX',
    comentarios: 'Comentario opcional',
    nota: '#'
}));

function GestRubrica() {
    const columns = [
        {
            key: 'aspecto',
            label: () => <Input value='Aspecto' />
        },
        {
            key: 'comentarios',
            label: () => <Input value='Comentarios' />
        },
        {
            key: 'nota',
            label: () => <Input value='Nota' />
        },
    ];

    return (
        <div className='gestrubrica'>
            <PageTitle title="Gestión de Rúbrica" />
            <Table 
                editable
                columns={columns}
                data={data}
            />
        </div>
    )
}

export default GestRubrica;