import React from 'react';
import './styles.css'
import PageTitle from '../../../components/PageTitle';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import File from '../../../components/File';
import Button from '../../../components/Button';

function Cargarlistas() {
  return (
    <div className='cargar-listas'>
        <PageTitle title="Cargar Listas"/>
        <form action="">
            <h2>Periodo académico:</h2>
            <div className='form-group periodo_academico'>
                <Input 
                    inline
                    label="Año"
                    type="number"
                    min="2024"
                    name="year"
                    placeholder="aaaa"
                />
                <Select inline label="Periodo"name="period" placeholder="Selecciona un periodo">
                    <option value="1">1</option>
                    <option value="3">3</option>
                </Select>
            </div>
            <ul className='listas'>
                <li>
                    <label>
                        <h2>Lista de estudiantes:</h2>
                    </label>
                    <File />
                </li>
                <li>
                    <label>
                        <h2>Lista de docentes:</h2>
                    </label>
                    <File />
                </li>
            </ul>
            <div className='acciones-listas'>
                <Button type="submit">
                    Eliminar
                </Button>
                <div>
                    <Button type="submit">
                        Editar
                    </Button>
                    <Button type="submit">
                        Guardar
                    </Button>
                </div>
            </div>
        </form>
    </div>
  );
}

export default Cargarlistas;