import React from 'react';
import './styles.scss';
import Button from '../../../components/Button';
import { useNavigate } from "react-router-dom";
import Arrow from '../../../components/Arrow';

function Home() {
  const navigate = useNavigate();

  return (
    <>
      <div className='dates'>
        <h1>Fechas importantes</h1>
        <div className='dates-content'>
          <div className='dates-item'>
            <span className='dates-item-description'>Descripción</span>
            <span className='dates-item-date'>dd-mm-aa</span>
          </div>
          <div className='dates-item'>
            <span className='dates-item-description'>Descripción</span>
            <span className='dates-item-date'>dd-mm-aa</span>
          </div>
          <div className='dates-item'>
            <span className='dates-item-description'>Descripción</span>
            <span className='dates-item-date'>dd-mm-aa</span>
          </div>
        </div>
      </div>
      <div className='grades'>
          <h1>Trabajo de grado 1</h1>
          <div className='estudiante-menu'>
            <div className='estudiante-menu-group'>
              <Button  size="medium" className="btn-lock">
                1. Matriculación TG1
              </Button>
              <Arrow />
              <Button size="medium" className="btn-lock">
                2. Reunión informativa
              </Button>
              <Arrow />
              <div className='estudiante-menu-last'>
                <Button onClick={() => navigate('/estudiante/propuesta')} size="medium" className="btn-submit">
                  3. Inscripción de propuesta TG1
                </Button>
                <Arrow type="right" size="300px"/>
              </div>
            </div>
            <div className='estudiante-menu-group'>  
              <Button size="medium" className="btn-lock">
                4. Asignación de tutor
              </Button>
              <Arrow />
              <Button onClick={() => navigate('/estudiante/seguimiento')} size="medium" className="btn-submit">
                5. Seguimiento de la propuesta
              </Button>
              <Arrow />
              <div className='estudiante-menu-last'>
                <Button onClick={() => navigate('/estudiante/institucionalizacion')} size="medium" block className="btn-submit">
                  6. Institucionalización
                </Button>
                <Arrow type="right" size="300px"/>
              </div>
            </div>
            <div className='estudiante-menu-group'>  
              <Button size="medium" className="btn-lock">
                7. Evaluación
              </Button>
            </div>
          </div>
      </div>
    </>
  );
}

export default Home;