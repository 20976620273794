import { AiFillHome } from "react-icons/ai";
import { FaFileUpload, FaChalkboardTeacher } from "react-icons/fa";
import { PiStudentBold } from "react-icons/pi";
import { HiSpeakerphone, HiUserGroup } from "react-icons/hi";
import Home from '../pages/coordinador/Home';
import Proyectos from '../pages/coordinador/Proyectos';
import ProyectoDetalle from '../pages/coordinador/Proyectos/Detalle';

import Cargarlistas from '../pages/coordinador/Cargarlistas';

import GestEstudiante from "../pages/coordinador/GestEstudiante";
import GestEstudianteDetalle from "../pages/coordinador/GestEstudiante/Detalle";

import GestDocente from "../pages/coordinador/GestDocente";
import GestDocenteDetalle from "../pages/coordinador/GestDocente/Detalle";

import GestRubrica from '../pages/coordinador/GestRubrica';

import Comunicados from "../pages/coordinador/Comunicados";
import ComunicadosInformativa from "../pages/coordinador/Comunicados/Informativa";
import ComunicadosAnuncios from "../pages/coordinador/Comunicados/Anuncios";
import ComunicadosFechas from "../pages/coordinador/Comunicados/Fechas";

export const menu = [
    {
        title: "Inicio",
        icon:   <AiFillHome/>,
        link: "",
    },
    {
        title: "Cargar Listas",
        icon:   <FaFileUpload/>,
        link: "cargarlistas",
    },
    {
        title: "Comunicados",
        icon:   <HiSpeakerphone/>,
        link: "comunicados",
    },
    {
        title: "Gestión Estudiantes",
        icon:   <PiStudentBold/>,
        link: "gestestudiantes",
    },
    {
        title: "Gestión Docentes",
        icon:   <FaChalkboardTeacher/>,
        link: "gestdocentes",
    },
    {
        title: "Gestión Rúbrica",
        icon:   <FaChalkboardTeacher/>,
        link: "gestrubrica",
    },
    {
        title: "Proyectos",
        icon:   <HiUserGroup/>,
        link: "proyectos",
    }
]


export const routes = [
    {
        path: "/",
        element: Home,
    },
    {
        path: "/cargarlistas",
        element: Cargarlistas,
    },
    {
        path: "/comunicados",
        element: Comunicados,
    },
    {
        path: "/comunicados/reunion-informativa",
        element: ComunicadosInformativa,
    },
    {
        path: "/comunicados/anuncios-generales",
        element: ComunicadosAnuncios,
    },
    {
        path: "/comunicados/fechas-importantes",
        element: ComunicadosFechas,
    },
    {
        path: "/gestestudiantes",
        element: GestEstudiante,
    },
    {
        path: "/gestestudiantes/:id",
        element: GestEstudianteDetalle,
    },
    {
        path: "/gestdocentes",
        element: GestDocente,
    },
    {
        path: "/gestdocentes/:id",
        element: GestDocenteDetalle,
    },
    {
        path: "/gestrubrica",
        element: GestRubrica,
    },
    {
        path: "/proyectos",
        element: Proyectos,
    },
    {
        path: "/proyectos/:id",
        element: ProyectoDetalle,
    },
]