import React from 'react';
import './styles.scss';
import Button from '../../../components/Button';
import Info from '../../../components/Info';
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  return (
    <>
      <div className='dates'>
        <h1>Fechas importantes</h1>
        <div className='dates-content'>
          <div className='dates-item'>
            <span className='dates-item-description'>Descripción</span>
            <span className='dates-item-date'>dd-mm-aa</span>
          </div>
          <div className='dates-item'>
            <span className='dates-item-description'>Descripción</span>
            <span className='dates-item-date'>dd-mm-aa</span>
          </div>
          <div className='dates-item'>
            <span className='dates-item-description'>Descripción</span>
            <span className='dates-item-date'>dd-mm-aa</span>
          </div>
        </div>
      </div>
      <div className='grades'>
          <h1>Trabajo de grado 1</h1>
          <div className='grades-content'>
            <div className='grades-menu'>
                <Button 
                  type="submit" 
                  size="medium"
                  onClick={() => navigate('/cordinacion/cargarlistas')}
                  block
                >
                  Cargar Listas
                </Button>
                <Button 
                  type="submit" 
                  size="medium" 
                  onClick={() => navigate('/cordinacion/comunicados')}
                  block
                >
                  Comunicaciones
                </Button>
                <Button 
                  type="primary" 
                  size="medium" 
                  onClick={() => navigate('/cordinacion/gestdocentes')}
                  block
                >
                  Gestionar docentes
                </Button>
                <Button 
                  type="primary" 
                  size="medium" 
                  onClick={() => navigate('/cordinacion/gestestudiantes')}
                  block
                >
                  Gestionar estudiantes
                </Button>
                <Button 
                  type="primary" 
                  size="medium" 
                  onClick={() => navigate('/cordinacion/proyectos')}
                  block
                >
                  Gestionar proyectos
                </Button>
                <Button 
                  type="primary" 
                  size="medium" 
                  onClick={() => navigate('/cordinacion/rubrica')}
                  block
                >
                  Gestionar rúbrica
                </Button>
            </div>
            <div className='grades-results'>
              <Info.Result label="Nº de estudiantes registrados" value="#" />
              <Info.Result label="Nº de profesores registrados" value="#" />
              <Info.Result label="Nº de proyectos registrados" value="#" />
            </div>
          </div>
      </div>
    </>
  );
}

export default Home;