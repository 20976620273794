import React, { useRef } from 'react';
import './styles.css'
import { BiSolidUser, BiSolidLock } from 'react-icons/bi'
import Logo from '../../assets/img/logo.png';
import Checkbox from '../../components/Checkbox';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useNavigate, useLocation } from "react-router-dom";

function Login() {
  const userRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const loginAction = (e) => {
    e.preventDefault();
    const rol = location.search.replace('?tipo=', '') || 'docente';
    localStorage.setItem('permisos', rol)
    localStorage.setItem('session', "INIT_SESSION")
    localStorage.setItem('user', userRef.current.value)
    navigate(`/${rol}`)
  }

  return (
    <div className="login-section">
        <div className="presentacion">
            <img src={Logo} alt="LogoSIGETG" id="logo_login"/>
            <label>
                <h1>SIGETG</h1>
                <h5>Sistema de Información para la Gestión de Trabajos de Grado</h5>
            </label>
        </div>
        <form onSubmit={loginAction} className='form mt2'>
            <div className='form-group'>
                <Input 
                    inline
                    id='user'
                    ref={userRef}
                    type="text"
                    placeholder="Usuario"
                    icon={<BiSolidUser />}
                />
            </div>
            <div className='form-group'>
                <Input 
                    inline
                    id='password'
                    type="password"
                    placeholder="Contraseña"
                    icon={<BiSolidLock />}
                />
            </div>

            <div className='form-group mt2 mb2'>
                <Checkbox 
                    label="Recordarme"
                    name="Recordarme"
                />
                <a href="#">Olvidé mi Contraseña</a>
            </div>
            <Button type="submit">
                Iniciar Sesión
            </Button>
        </form>
    </div>
  );
}

export default Login;