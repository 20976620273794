import { useState } from 'react';
import './styles.scss';
import PageTitle from '../../../components/PageTitle';
import Card from '../../../components/Card';
import Button from '../../../components/Button';
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { IoMdCheckmark } from "react-icons/io";
import ModalConfirm from './ModalConfirm';
import ModalReject from './ModalReject';


const allCards = Array.from({ length: 10 })

function Propuestas() {
  const navigate = useNavigate();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openReject, setOpenReject] = useState(false);

  return (
    <>
      <PageTitle title="Propuestas" />
      <div className='propuestas-cards'>
        {allCards.map((_v, index) => (
          <Card 
            key={String(index)}
            title="Tema"
            color="#dfeefb" 
            onClick={() => navigate('/docente/propuestas/detalle')}
            content={() => (
            <div className='propuesta-card'>
              <div className='propuesta-info'>
                <ul>
                  <li>Nombre estudiante 1</li>
                  <li>Nombre estudiante 2</li>
                </ul>
              </div>
              <div className='propuesta-actions'>
                <Button onClick={() => setOpenConfirm(true)} color="#02a696">
                  <IoMdCheckmark  />
                </Button>
                <Button onClick={() => setOpenReject(true)} color="#de4a4b">
                  <RxCross2 />
                </Button>
              </div>
            </div>
          )} />
        ))}
      </div>
      <ModalConfirm
        isOpen={openConfirm}
        onClose={() => setOpenConfirm(false)}
        confirmText="!Propuesta aceptada!"
        text={<span>¿Estás seguro de <b>aceptar</b> ser tutor de esta propuesta?</span>}
      />
      <ModalReject
        isOpen={openReject}
        onClose={() => setOpenReject(false)}
        confirmText="!Propuesta rechazada!"
        text={<span>¿Estás seguro de <b>rechazar</b> ser tutor de esta propuesta?</span>}
      />
    </>
  );
}

export default Propuestas;