import React, { useState } from 'react';
import classnames from 'classnames';
import './styles.css';
import { Link, useNavigate } from "react-router-dom";
import { IoNotifications } from 'react-icons/io5'
import { CgMenuGridO } from "react-icons/cg";
import { IoIosClose } from "react-icons/io";
import Logo from '../../assets/img/logo.png';
import Button from '../../components/Button';

function Panelcontrol({ children, menu, permisos }) {
  const navigate = useNavigate();
  const user = localStorage.getItem('user');

  const [openMenu, setOpenMenu] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('permisos')
    localStorage.removeItem('user')
    localStorage.removeItem('session')
    navigate('/')
  }

  return (
    <>
      <div id='panel' className={classnames({ 'panel--collapsed': !openMenu})}>
        <header>
          <button onClick={() => setOpenMenu(false)} className='Menu_Button close-button'>
          <IoIosClose className='Menu_Icon'/>
          </button>
          <div id="contenedor_header">
            <span id="contenedor_presentacion">
              <img src={Logo} alt="LogoSIGETG" id="logo_panel"/>
              <h3>SIGETG</h3>
            </span>
            <span onClick={() => setOpenNotifications(o => !o)}>
            <IoNotifications className="panel-icon"/>
            </span>
          </div>
        </header>
        <main>
          <h2>Bienvenido, {user}</h2>
          <div className="pages">
            <ul>
              {menu.map((value) => {
                return (
                  <li>
                    <Link to={`/${permisos}/${value.link}`}>
                      <span className="List_Pages">
                        <span className='Page_Icon'>{value.icon}</span>
                        <h3>{value.title}</h3>
                      </span>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </main>
        <footer>
          <Button 
            type="submit" 
            className="btn-logout"
            onClick={handleLogout}
          >
            Cerrar Sesión
          </Button>
        </footer>
      </div>
      <button onClick={() => setOpenMenu(o => !o)} className='Menu_Button'>
        <CgMenuGridO className='Menu_Icon'/>
      </button>
      <div className="container">
        {children}
        <div className={classnames('notifications', {
          'notifications--show': openNotifications
        })}>
          <div className='notifications-header'>
            <button onClick={() => setOpenNotifications(false)} className='Menu_Button'>
              <IoIosClose className='Menu_Icon'/>
            </button>
          </div>
          <div className='notifications-body'>
            No tienes notificaciones
          </div>
        </div>
      </div>
    </>
  );
}

export default Panelcontrol;

/*

*/