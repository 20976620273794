import './styles.scss';

function Modal ({ title, content, onClose }) {
    return (
        <div className="modal">
            <div className='modal-container'>
                <button onClick={onClose} className="modal-close">x</button>
                {title && <div className="modal-header">{title}</div>}
                <div className="modal-body">{content()}</div>
            </div>
        </div>
    )
}

export default Modal;