import { useState } from 'react';
import classnames from 'classnames';
import './styles.scss'
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";


function Block({ title, children, collapsable, footer }) {

    const defaultCollapsed = !!collapsable;
    const [collapsed, setCollapsed] = useState(defaultCollapsed);

    const handleOnClick = () => {
        if(collapsable) {
            setCollapsed(c => !c);
        }
    }

    return (
        <div className='block'>
            <div className={classnames('block-header', {
                'block-collapsable': !!collapsable,
                'block--collapsed': collapsed
            })} onClick={handleOnClick}>
                <span>{title}</span>
                {collapsable && (
                    <span className='block-collapse'>
                        {collapsed ? (
                            <MdOutlineKeyboardArrowDown size="4rem"/>
                        ) : (
                            <MdOutlineKeyboardArrowUp size="4rem" />
                        )}
                    </span>
                )}
            </div>
            {!collapsed && <div className='block-body'>
                {children}
                {footer && <div className='block-footer'>{footer}</div>}
            </div>}
        </div>
    )
}

export default Block;