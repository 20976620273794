import React from 'react';
import './styles.scss';
import PageTitle from '../../../components/PageTitle';

function Seguimiento() {
  return (
    <div className='seguimiento'>
      <PageTitle title="Seguimiento"/>
        <div className='seguimiento-finalizado'>
            <h2>Proceso de seguimiento finalizado</h2>
            <h3>Estado</h3>
            <div className='seguimiento-estado'>
                <span className='seguimiento-circle'></span>
                <span className='seguimiento-texto'>N/N compromisos cumplidos</span>
            </div>
        </div>
    </div>
  );
}

export default Seguimiento;