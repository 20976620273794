import './index.scss'
import { FaArrowDownLong, FaArrowRightLong } from "react-icons/fa6"

function Arrow({ type, size }) {

    if (type === 'right') {
        return (
            <span className='arrow arrow--right'>
                <span className='arrow-content'>
                    <span><FaArrowRightLong /></span>
                    <span className='arrow-vertical' style={{ height: size}}></span>
                    <span className='arrow-horizontal'></span>
                </span>
            </span>
        )
    }
    return (
        <span className='arrow'>
            <span className='arrow-content'>
                <span className='arrow-vertical'></span>
                <span><FaArrowDownLong /></span>
            </span>
        </span>
    )
}

export default Arrow;