import './style.scss';
import classnames from 'classnames'
import { useState } from 'react'
import Input from '../Input'

const ButtonAdd = ({ onClick, position}) => <button className={classnames("table-button-add", {
    [`table-button-add--${position}`]: !!position
})} onClick={onClick}>+</button>

function Table({ columns: defaultColumns, data, onSearch, onRowClick, editable }) {

    const [columns, setColumns] = useState(defaultColumns);
    const handleRowClick = (data) => () => onRowClick && onRowClick(data);

    const handleAddColumn = (index) => () => {
        setColumns(columns => {
            const newColumns = [...columns]
            newColumns.splice(index, 0, { key: 'key', label: () => <Input />})
            console.log(newColumns);
            return newColumns;
        })
    }

    return (
        <div className='table-container'>
            {onSearch && (
                <div className="table-search">
                    <Input 
                        size="big"
                        placeholder="Buscar"
                        onChange={onSearch}
                    />
                </div>
            )}
            <div className='table-content'>
                <table>
                    <thead>
                        <tr>
                            {columns.map(({label, key, style}, index) => (
                                <th style={style} key={key} >
                                    {typeof label === 'function' ? label() : label}
                                    {editable && <ButtonAdd onClick={handleAddColumn(index + 1)} />}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {columns.length && data.map(data => (
                            <tr className={onRowClick ? 'pointer' : ''} key={data.id} onClick={handleRowClick(data)}>
                                {columns.map(({ key, render }) => (
                                    <td>{render ? render(data[key]) : data[key]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Table;
