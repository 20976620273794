export const teachers = [
  {
    "id": 1,
    "email": "profesor1@example.com",
    "name": "Juan",
    "last_name": "Gómez",
    "is_active": true,
    "password": "contraseña123",
    "num_Proyectos": 5,
    "horas": 12,
    "confirmation": true,
    "es_asesor": true,
  },
  {
    "id": 2,
    "email": "profesor2@example.com",
    "name": "María",
    "last_name": "Rodríguez",
    "is_active": true,
    "password": "secreto456",
    "num_Proyectos": 3,
    "horas": 10,
    "confirmation": true,
    "es_asesor": true,
    "es_jurado": true,
  },
  {
    "id": 3,
    "email": "profesor3@example.com",
    "name": "Luis",
    "last_name": "Pérez",
    "is_active": false,
    "password": "clave789",
    "num_Proyectos": 7,
    "horas": 5,
    "confirmation": false,
    "es_jurado": true,
  },
  {
    "id": 4,
    "email": "profesor4@example.com",
    "name": "Ana",
    "last_name": "Martínez",
    "is_active": true,
    "password": "segura123",
    "num_Proyectos": 6,
    "horas": 6,
    "confirmation": true,
    "es_jurado": true,
  },
  {
    "id": 5,
    "email": "profesor5@example.com",
    "name": "Carlos",
    "last_name": "López",
    "is_active": true,
    "password": "protegida456",
    "num_Proyectos": 4,
    "horas": 3,
    "confirmation": true,
    "es_jurado": true,
  },
  {
    "id": 6,
    "email": "profesor6@example.com",
    "name": "Sofía",
    "last_name": "González",
    "is_active": false,
    "password": "secreta789",
    "num_Proyectos": 6,
    "horas": 12,
    "confirmation": false,
    "es_asesor": true,
    "es_jurado": true,
  },
  {
    "id": 7,
    "email": "profesor7@example.com",
    "name": "Pedro",
    "last_name": "Hernández",
    "is_active": true,
    "password": "clave123",
    "num_Proyectos": 5,
    "horas": 25,
    "confirmation": true
  },
  {
    "id": 8,
    "email": "profesor8@example.com",
    "name": "Laura",
    "last_name": "Díaz",
    "is_active": true,
    "password": "seguridad456",
    "num_Proyectos": 4,
    "horas": 20,
    "confirmation": true
  },
  {
    "id": 9,
    "email": "profesor9@example.com",
    "name": "Javier",
    "last_name": "Ramírez",
    "is_active": false,
    "password": "contraseña789",
    "num_Proyectos": 2,
    "horas": 18,
    "confirmation": false
  },
  {
    "id": 10,
    "email": "profesor10@example.com",
    "name": "Elena",
    "last_name": "Sánchez",
    "is_active": true,
    "password": "secreto123",
    "num_Proyectos": 3,
    "horas": 23,
    "confirmation": true
  }
]