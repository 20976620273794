import React from 'react';
import PageTitle from '../../../components/PageTitle';
import './styles.css'
import {students} from '../../../db/dummyStudents';
import Table from '../../../components/Table';
import Info from '../../../components/Info';
import {draft_projects} from '../../../db/dummyProjects';
import { useNavigate } from 'react-router-dom';

function project_name_search(project_id){
  const project = draft_projects.find((project) => project.draft_project_id === project_id);
}

function teacher_name_search(){}

function GestEstudiante() {
  const navigate = useNavigate();

  const columns = [
    {
      key: 'id',
      label: 'ID',
      style: { width: '40px'}
    },
    {
      key: 'name',
      label: 'Estudiante',
    },
    {
      key: 'last_name',
      label: 'Apellido',
    },
    {
      key: 'Asesor',
      label: 'Asesor',
    },
    {
      key: 'proyecto',
      label: 'Proyecto',
    }
  ]

  const onSearch = (search) => {
    console.log(search, 'search')
  }

  const onRowClick = (data) => {
    navigate(`/cordinacion/gestestudiantes/${data.id}`);
 }

  return (
    <React.Fragment>
        <PageTitle title="Gestión Estudiantes"/>
        <Info.Result label="Nº estudiantes registrados" value="#"/>
        <Info.Result label="Nº estudiantes activos" value="#"/>
        <Table
          columns={columns}
          data={students}
          onSearch={onSearch}
          onRowClick={onRowClick}
        />
    </React.Fragment>
  );
}

export default GestEstudiante;