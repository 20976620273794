import { useState } from 'react';
import classnames from 'classnames';
import './style.scss';

function Checkbox({ label, checked, id, name, size }) {
    const [isChecked, setIsChecked] = useState(checked);

    const handleOnChange = () => setIsChecked(o => !o);

    return (
        <label for={`${id}`} className={classnames("checkbox-container", {
            [`checkbox--${size}`]: !!size,
        })}>
            <div className='checkbox-content' onClick={handleOnChange}>
                {label && <span className='checkbox-label'>{label}</span>}
                <input type="checkbox" checked={isChecked} name={name} id={id}/>
                <span className="checkbox-checkmark"></span>
            </div>
        </label>
    )
}

export default Checkbox;
