import { AiFillHome } from "react-icons/ai";
import { GiArchiveRegister } from "react-icons/gi";
import { BiCurrentLocation } from "react-icons/bi";
import { SiGoogleforms } from "react-icons/si";
import Home from '../pages/estudiante/Home';
import Propuesta from '../pages/estudiante/Propuesta';
import PropuestaConfirmacion from '../pages/estudiante/Propuesta/Confirmacion';
import Seguimiento from "../pages/estudiante/Seguimiento";
import SeguimientoFinalizado from "../pages/estudiante/Seguimiento/Finalizado";
import Institucionalizacion from "../pages/estudiante/Institucionalizacion";
import Evaluacion from "../pages/estudiante/Evaluacion";
import EvaluacionNotas from "../pages/estudiante/Evaluacion/Notas";

export const menu = [
    {
        title: "Inicio",
        icon:   <AiFillHome/>,
        link: "",
    },
    {
        title: "Propuesta",
        icon:   <GiArchiveRegister />,
        link: "propuesta"
    },
    {
        title: "Seguimiento",
        icon:   <BiCurrentLocation />,
        link: "seguimiento"
    },
    {
        title: "Institucionalización",
        icon:   <SiGoogleforms />,
        link: "institucionalizacion"
    },
    {
        title: "Evaluación",
        icon:   <SiGoogleforms />,
        link: "evaluacion"
    }
]


export const routes = [
    {
        path: "/",
        element: Home,
    },
    {
        path: "/propuesta",
        element: Propuesta,
    },
    {
        path: "/propuesta/confirmacion",
        element: PropuestaConfirmacion,
    },
    {
        path: "/seguimiento",
        element: Seguimiento,
    },
    {
        path: "/seguimiento/finalizado",
        element: SeguimientoFinalizado,
    },
    {
        path: "/institucionalizacion",
        element: Institucionalizacion,
    },
    {
        path: "/evaluacion",
        element: Evaluacion,
    },
    {
        path: "/evaluacion/notas",
        element: EvaluacionNotas,
    },
]